import React from 'react'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { Route, Switch } from 'react-router-dom'
// import { LoadScript } from '@react-google-maps/api'
//
import 'assets/vendors/style'
import store, { history } from 'appRedux/store'
import App from 'containers/App'

const NextApp = () => (
  <Provider store={store}>
    {/* <LoadScript
      id="script-loader"
			googleMapsApiKey="AIzaSyBHy0FBlQUBTUl-rS0qTIW6R34hXYTmj_U"
      language="en"
      region="us"
    > */}
    <ConnectedRouter history={history}>
      <Switch>
        <Route path="/" component={App} />
      </Switch>
    </ConnectedRouter>
    {/* </LoadScript> */}
  </Provider>
)

export default NextApp
